<template>
  <div
    :style="bookingStyle"
    :class="isRecent ? 'blink booking-category-border-style' : 'booking-category-border-style'"
    class="card-booking"
    @click.stop="onCardClick"
    @mouseover="showDropDown = true"
    @mouseleave="showDropDown = false"
  >
    <div
      v-if="showDropDown"
      class="pull-right"
    >
      <d-dropdown-form
        :key="'d-drop-down' + dropDownKey"
        :hide="hide"
        :show="show"
        :right="true"
        :identifier="'identifier-' + booking.id"
      >
        <template v-slot:dropdownbutton>
          <d-button
            icon="icon-more-alt pointer"
            @on:button-click="show = !show"
          />
        </template>
        <template v-slot:dropdownbody>
          <a
            href="#"
            class="dropdown-item"
            @click="$emit('on:booking-click')"
          >
            <h6
              class="simple-label-avenir pointer"
            >
              <i class="icofont icofont-eye-alt pr-2"></i>
              {{ $t('show_booking') }}
            </h6>
          </a>
          <div class="border-bottom mt-1 mb-1"></div>
          <a
            href="#"
            class="dropdown-item"
            @click="actionCopyMove('move', booking.id)"
          >
            <h6
              class="simple-label-avenir pointer"
            >
              <i class="icofont icofont-copy-black pr-2"></i>
              {{ $t('move_booking') }}
            </h6>
          </a>
          <div class="border-bottom mt-1 mb-1"></div>
          <a
            href="#"
            class="dropdown-item"
            @click="actionCopyMove('copy', booking.id)"
          >
            <h6
              class="simple-label-avenir pointer"
            >
              <i class="icofont icofont-copy-alt pr-2"></i>
              {{ $t('copy_booking') }}
            </h6>
          </a>
          <div class="border-bottom mt-1 mb-1"></div>
          <a
            href="#"
            class="dropdown-item"
            @click="$emit('on:booking-delete-modal')"
          >
            <h6
              class="simple-label-avenir pointer"
            >
              <i class="icofont icofont-trash pr-2"></i>
              {{ $t('delete_booking_') }}
            </h6>
          </a>
        </template>
      </d-dropdown-form>
    </div>
    <div
      :id="'target' + booking.id"
      :class="cardInlineParticipants ? 'flex-row' : 'flex-column' "
      class="d-flex"
    >
      <div
        class="pointer booking-trancuate"
        :alt="booking.name"
      >
        {{ booking.name }}
      </div>
      <template
        v-if="(booking.match !== null || booking.activityType === 'lesson')"
      >
        <div
          class="participants"
        >
          {{ booking.participantsCount }} / {{ maxParticipantsCountLimit }}
          <span v-if="!cardInlineParticipants">
            {{ $t('views.playground.steps.step-3.participants-short') }}
          </span>
        </div>
      </template>
    </div>
    <div
      class="card-booking-footer pointer"
    >
      <div
        class="duration text-ellipsis"
        :class="cardHeight >= 30 && cardHeight <= 40 ? 'xs-duration' : 'sm-duration'"
        v-if="cardHeight >= 30"
      >
        {{ bookingStartAt }} {{ endAt }}
      </div>
      <div
        v-if="cardHeight >= 30"
      >
        <div
          :class="displayMode ? 'horizontal-currency' : 'vertical-currency'"
        >
          <b-avatar
            :size="cardHeight >= 30 && cardHeight <= 40 ? '13px' : '20px'"
            :class="currencyHeight"
          >
            <i class="icofont icofont-cur-dollar"></i>
          </b-avatar>
        </div>
        <div
          v-if="null !== booking.comment"
          :class="displayMode ? 'horizontal-notes' : 'vertical-notes'"
        >
          <b-avatar
            icon="chat-text-fill"
            class="bck-gradient-red"
            :class="cardHeight >= 30 && cardHeight <= 40 ? 'mt-1': ''"
            :size="cardHeight >= 30 && cardHeight <= 40 ? '13px' : '20px'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {FORMULA_TYPE, LESSON_TYPE} from "@/classes/doinsport/Price";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {toTimeFormat} from "@/utils/timezone";

export default {
  data: () => ({
    show: false,
    hide: false,
    dropDownKey: 0,
    showDropDown: false,

  }),
  props: {
    duration: {
      type: Number,
      default: () => 0,
    },
    booking: {
      type: Object,
      default: () => {
      },
    },
    size: {
      type: Number,
      default: () => 1,
    },
    orientation: {
      type: String,
      default: 'vertical',
    },
    params: {
      type: Object,
      default: () => {
      },
      display: {
        type: Boolean,
        default: false
      },
    }
  },
  computed: {
    displayMode() {
      return this.orientation === 'horizontal' && this.cardHeight <= 100;
    },
    currencyHeight() {
      const customHeight = this.cardHeight >= 30 && this.cardHeight <= 40 ? 'mt-1' : 1;

      return this.isPaid ? 'bck-success ' + customHeight : 'bck-gradient-warning ' + customHeight;
    },
    endAt() {
      if (this.orientation === 'horizontal') {
        if (this.cardHeight > 100 && this.cardHeight < 120) {
          return '';
        } else if (this.cardHeight < 75) {
          return '';
        } else {
          return `- ${this.bookingEndAt}`;
        }
      } else {
        return `- ${this.bookingEndAt}`;
      }
    },
    isPaid() {
      return null !== this.booking.consideredAsPaidAt || this.booking.restToPay <= 0;
    },
    cardHeight() {
      return this.orientation === 'vertical' ? this.duration * this.size : this.duration * 2 * this.size;
    },
    cardInlineParticipants() {
      if (this.duration <= 20 && this.duration >= 15) {
        return this.size <= 3.1;
      } else if (this.duration < 15) {
        return this.size <= 3.9;
      } else if ((this.booking.match == null || this.booking.activityType !== 'lesson') && (this.duration >= 30 && this.duration < 45)) {
        return this.size <= 1.6;
      } else {
        return this.size === 1;
      }
    },
    bookingStyle() {
      return {
        '--booking-category': this.getColorBooking(),
      }
    },
    operations() {
      return this.$store.getters["planning/getOperations"];
    },
    isRecent() {
      const operation = this.operations.find(el => el.id === this.booking.id);

      return ("undefined" !== typeof operation);
    },
    maxParticipantsCountLimit() {
      return this.booking.maxParticipantsCountLimit;
    },
    bookingStartAt() {
      return toTimeFormat(this.booking.startAt);
    },
    bookingEndAt() {
      return toTimeFormat(this.booking.endAt);
    }
  },
  methods: {
    onCardClick($event) {
      if ($event.target.id !== `identifier-${this.booking.id}`) {
        this.$emit('on:booking-click');
      }
    },
    scrollSmoothly() {
      const body = $("html, body");
      body.stop().animate({scrollTop: 0}, 500, 'swing', function () {
      });
    },

    actionCopyMove(type, bookingId) {
      this.scrollSmoothly();
      this.$store.dispatch('copyMove/set', {
        type: type,
        bookingId: bookingId,
        booking: this.booking,
      });
    },
    getColorBooking() {
      if (isNotUndefinedAndNotNull(this.booking.activityType) && this.booking.activityType === LESSON_TYPE) {
        return this.params.colors[LESSON_TYPE];
      } else if (isNotUndefinedAndNotNull(this.booking.activityType) && this.booking.activityType === FORMULA_TYPE) {
        return this.params.colors[FORMULA_TYPE];
      } else {
        if (this.booking.category) {
          let color = null;
          this.params.bookingCategories.forEach(item => {
            if (item.bookingCategory['@id'] === this.booking.category['@id']) {
              color = item.color;
            }
          });
          if (color) {
            return color;
          }
        }
        if (this.booking.match !== null) {
          return this.params.colors.match;
        }
        switch (this.booking.creationOrigin) {
          case "white_label_app":
            return this.params.colors.booking_from_app;
          case "doinsport_app":
            return this.params.colors.booking_from_app;
          case "web_site":
            return this.params.colors.booking_from_web;
          case "administration":
            return this.params.colors.booking_from_club;
          case "automatic":
            return this.params.colors.recurring_booking;
          default:
            return '#2DC301';
        }
      }
    }
  },
  mounted() {
    this.$store.commit('planning/removeOperation', this.booking);
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/bookings/_booking-cards.scss";

.booking-category-border-style {
  border-left: 10px solid var(--booking-category) !important;
}

.blink {
  animation: allblink 700ms infinite;
}

@keyframes allblink {
  0% {
  }
  50% {
    background-color: var(--booking-category);
    opacity: 0.8;
  }
}

.bck-success {
  background-color: #3db112 !important;
  color: #fff;
}

</style>
